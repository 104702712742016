export const navbarLink = [
  {
    label: "About",
    path: "https://bj88ph.live/af/Zd946zCP/inplay-official",
  },
  {
    label: "Responsible Gaming",
    path: "https://bj88ph.live/af/Zd946zCP/inplay-official",
  },
  {
    label: "Most Played",
    path: "https://bj88ph.live/af/Zd946zCP/inplay-official",
  },
  {
    label: "Promotions",
    path: "https://bj88ph.live/af/Zd946zCP/inplay-official",
  },
  {
    label: "FAQs",
    path: "https://bj88ph.live/af/Zd946zCP/inplay-official",
  },
];
