import React from "react";
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper/modules";
import HeroCarousel from "../../data/Carousel.data";
const HeroBanner = () => {
  return (
    <section>
      <div>
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
          navigation={true}
          modules={[Pagination, Autoplay, EffectFade, Navigation]}
          className="mySwiper"
        >
          {HeroCarousel.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item.img}
                class="img-fluid w-100 d-block"
                alt={item.alt}
                srcset=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
    </section>
  );
};

export default HeroBanner;
