import React from "react";

const Download = () => {
  return (
    <section className="download__container">
      <div className="container">
        <div className="experience__wrapper">
          <h1 className="fusion-title ">
            Download our Android App for an on-the-go playing experience like no
            other!
          </h1>
          <div
            class="fusion-title title fusion-title-4 fusion-sep-none fusion-title-text fusion-title-size-three"
            // style="--awb-text-color:var(--awb-color1);--awb-margin-bottom:.2em;--awb-font-size:36px;"
          >
            <div className="download__list my-4">
              <h3
                class="title-heading-left"
                // style='font-family:"Poppins";font-style:normal;font-weight:500;margin:0;font-size:1em;line-height:1.4;'
              >
                <ol>
                  <li>
                    On desktop, click App download button and scan QR to
                    download APK file
                    <br />
                    On mobile, click App download button and download APK file.
                  </li>
                  <li>
                    Tap download anyway if your device prompts that file might
                    be harmful.
                  </li>
                  <li>
                    If your phone does not give you the option to open the
                    downloaded file, go to File Explorer &gt; Downloads &gt; tap
                    .apk file.
                  </li>
                  <li>Install app.</li>
                </ol>
              </h3>
            </div>
          </div>
          <div className="download__wrapper w-100 d-flex align-items-start justify-content-center justify-content-lg-start justify-content-md-center">
            <a
              href="https://bj88ph.live/af/Zd946zCP/inplay-official"
              className="fusion-button button-flat"
            >
              <span className="text-black fw-bold">Download App</span>
              <i
                class="fa-download fas button-icon-right ms-3 text-black"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
