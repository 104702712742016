import React from "react";

const Experience = () => {
  return (
    <section className="experience__container">
      <div className="container">
        <div className="experience__wrapper">
          <h1 className="fusion-title ">
            Experience Online Casino Games at InPlay!
          </h1>
          <div class="fusion-title title fusion-title-4 fusion-sep-none fusion-title-text fusion-title-size-three">
            <h3 class="title-heading-left">
              Real Entertainment only on InPlay.
            </h3>
            <p className="fustion-title-par mb-4">
              Play SLOTS, LIVE Baccarat and Roulette – PAGCOR Licensed Casino.
            </p>
          </div>
          <div className="experience__wrapper w-100 d-flex align-items-center">
            <a
              href="https://bj88ph.live/af/Zd946zCP/inplay-official"
              className="fusion-button button-flat"
            >
              <span className="text-black fw-bold">
                Experience real entertainment
              </span>
              <i
                class="fa-long-arrow-alt-right fas button-icon-right ms-2 text-black"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
