const HeroCarousel = [
  {
    img: "../../assets/img/InPlay-Dream-Catcher-Promo_banner_2000x750-1.webp",
    alt: "hero 1",
  },
  {
    img: "../../assets/img/JiLi_s-SurPrize_2000x750px.webp",
    alt: "hero 2",
  },
  {
    img: "../../assets/img/Oct_Giveaway_allprizes_2000x750px.webp",
    alt: "hero 3",
  },
  {
    img: "../../assets/img/Win-Your-Share-Instawin_2000x750px.webp",
    alt: "hero 4",
  },
  { img: "../../assets/img/diamond-forever-2000x-750px.webp", alt: "hero 5" },
  { img: "../../assets/img/welcome-bonus_2000x750px.webp", alt: "hero 6" },
];
export default HeroCarousel;
