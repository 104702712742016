import React from "react";
import HeroBanner from "../components/Home/Banner";
import Marquee from "../components/Home/Marquee";
import Experience from "../section/Experience";
import Download from "../section/Download";
import DataList from "../section/DataList";
import Play from "../section/Play";
import Swipercomponent from "../components/swiper/Swipercomponent";

const Home = () => {
  return (
    <>
      <HeroBanner />
      <Marquee />
      <Experience />
      <Download />
      <DataList />
      <Play /> 
      <Swipercomponent />
    </>
  );
};

export default Home;
