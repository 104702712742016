export const StartProvider = [
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Dragon-Fishing.jpg",
    alt: "game 1",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Banana-Jones.jpg",
    alt: "game 3",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Cai-Shen-Fishing.jpg",
    alt: "game 4",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Baccarat-Deluxe.jpg",
    alt: "game 5",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/Enchanted-Garden.jpg",
    alt: "game 2",
  },
];
