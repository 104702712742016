export const Premium = [
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/evolution-200x75.png",
    alt: "imges1",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/habanero-1-200x75.png",
    alt: "imges2",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/jdb-1-200x75.png",
    alt: "imges3",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/jili-200x75.png",
    alt: "imges4",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/10/kingmaker-200x68.webp",
    alt: "imges5",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/09/NetEnt_logo-200x76.webp",
    alt: "imges6",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/pg-200x75.png",
    alt: "imges7",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/realtime-gaming-200x75.png",
    alt: "imges8",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/red-tiger-1-200x75.png",
    alt: "imges9",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/spadegaming-200x75.png",
    alt: "imges10",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/tpg-200x75.png",
    alt: "imges11",
  },
  {
    img: "https://inplay.ph/wp-content/uploads/2023/05/jili-200x75.png",
    alt: "imges11",
  },
];
