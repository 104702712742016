import React from "react";
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper/modules";
import SwiperData from "./SwiperData";
import { Premium } from "../../data/Premium.data";
import { StartProvider } from "../../data/Start.data";

const Swipercomponent = () => {
  return (
    <section className="">
      <div className="Premium-Start__container ">
        <div className="container py-5 px-0">
          <div className="title">
            <h1>Our Premium Content Providers</h1>
          </div>

          <SwiperData data={Premium} spaceBetween={"30"} slidesPerView={"6"} />
        </div>
        <div className="container py-5 px-0">
          <div className="title">
            <h1>Can’t decide where to start?</h1>
          </div>

          <SwiperData
            data={StartProvider}
            spaceBetween={"30"}
            slidesPerView={"4"}
          />
        </div>
        <div className="container ps-0">
          <a
            href="https://bj88ph.live/af/Zd946zCP/inplay-official"
            target="_blank"
            className="mt-4 fs-4 text-decoration-underline view-more-link "
            rel="noopener noreferrer"
          >
            View More Games
          </a>
        </div>
      </div>
      <div className="alert-section">
        <div className="container py-2">
          <p className="m-0 text-center">
            Players must be at least 21+ years old or older. Play responsibly
          </p>
        </div>
      </div>
    </section>
  );
};

export default Swipercomponent;
