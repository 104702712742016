import React from "react";

const DataList = () => {
  return (
    <section className="datalist__container">
      <div className="container">
        <div className="row row-cols-3 gx-5 gy-5">
          <div className="col-4  ">
            <div class="card bg-card-color">
              <div class="card-body text-center">
                <h1 class="card-title counter-box-counter-percentage text-white">365</h1>

                <p class="card-text counter-box-counter">Days of play</p>
              </div>
            </div>
          </div>
          <div className="col-4 ">
            {" "}
            <div class="card bg-card-color">
              <div class="card-body text-center">
                <h1 class="card-title counter-box-counter-percentage text-white">700+</h1>

                <p class="card-text counter-box-counter">Games</p>
              </div>
            </div>
          </div>
          <div className="col-4  ">
            {" "}
            <div class="card bg-card-color">
              <div class="card-body text-center">
                <h1 class="card-title counter-box-counter-percentage text-white">24/7</h1>

                <p class="card-text counter-box-counter">Support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataList;
