import React from "react";
import { BiLogoFacebook, BiLogoInstagram, BiLogoTiktok } from "react-icons/bi";
const Footer = () => {
  return (
    <footer className="text-black footer-container">
      <div className="upper-footer">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-3 col-md-12 d-flex justify-content-md-center justify-content-sm-center mb-4 ">
              <img
                src="https://inplay.ph/wp-content/uploads/2023/05/inPlay-Primary-Logo.png"
                height="65px"
                className=""
                alt="Inplay Logo"
                loading="lazy"
                // style="margin-top: -1px;"
              />
            </div>
            <div className="col-lg-4 col-md-12  ">
              <div className="d-flex justify-content-md-center justify-content-sm-center">
                <ul>
                  <li class="inplay-staticmenu-title">About Us</li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      About InPlay
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Responsible Gaming
                    </a>
                  </li>
                </ul>
                <ul>
                  <li class="inplay-staticmenu-title">Games</li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Live
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Slots
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Table Games
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Arcade
                    </a>
                  </li>
                </ul>
                <ul>
                  <li class="inplay-staticmenu-title">Support</li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-12   ">
              <div className="footer-right-container d-flex flex-column float-start">
                <h4 class=" widget-title ">Find Us On</h4>
                <div className="mb-2">
                  <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                    <BiLogoFacebook
                      style={{ fill: "black" }}
                      className="text-danger fs-3"
                    />
                  </a>
                  <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                    <BiLogoInstagram
                      style={{ fill: "black" }}
                      className="text-danger fs-3"
                    />
                  </a>
                  <a href="https://bj88ph.live/af/Zd946zCP/inplay-official">
                    <BiLogoTiktok
                      style={{ fill: "black" }}
                      className="text-danger fs-3"
                    />
                  </a>
                </div>
                <div className="">
                  <button
                    type="button"
                    class="btn btn-danger btn-header me-3 rounded-3 text-capitalize"
                    onClick={() =>
                      (window.location.href =
                        "https://bj88ph.live/af/Zd946zCP/inplay-official")
                    }
                  >
                    App
                    <i
                      class="fa-download fas button-icon-right ms-2"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        <div className="container">
          <p className="py-2">
            {" "}
            © Copyright 2023 All Rights reserved. | Inter-Active Entertainment
            Solutions Technologies, Inc.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
