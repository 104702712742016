import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const SwiperData = ({ data, slidesPerView }) => {
  return (
    <div>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true} // Set loop to true to loop through the slides
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item.img} className="game-provider-icon w-100 d-block" alt={item.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperData;
