import React from "react";
import { navbarLink } from "../../data/Navbar.data";

const Header = () => {
  return (
    <header>
      {/* <!-- Navbar --> */}
      {/* <!-- Navbar --> */}
      <nav class="navbar navbar-expand-lg header__container py-4">
        {/* <!-- Container wrapper --> */}
        <div class="container d-flex justify-content-between">
          {/* <!-- Navbar brand --> */}
          <a
            class="navbar-brand me-2"
            href="https://bj88ph.live/af/Zd946zCP/inplay-official"
          >
            <img
              src="https://inplay.ph/wp-content/uploads/2023/05/inPlay-Orange-Logo-small-200x77.png"
              height="40px"
              className=""
              alt="Inplay Logo"
              loading="lazy"
              // style="margin-top: -1px;"
            />
          </a>

          {/* <!-- Toggle button --> */}
          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarButtonsExample"
            aria-controls="navbarButtonsExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>

          {/* <!-- Collapsible wrapper --> */}
          <div
            class="collapse navbar-collapse justify-content-end "
            id="navbarButtonsExample"
          >
            <div class="d-flex align-items-center ">
              {navbarLink.map((item, index) => (
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 pe-3" key={index}>
                  <li class="nav-item">
                    <a
                      class="nav-link text-white fw-medium ms-1"
                      href={item.path}
                    >
                      {item.label}
                    </a>
                  </li>
                </ul>
              ))}

              <button
                type="button"
                class="btn btn-danger btn-header me-3 rounded-pill text-capitalize"
                onClick={() =>
                  (window.location.href =
                    "https://bj88ph.live/af/Zd946zCP/inplay-official")
                }
              >
                App
                <i
                  class="fa-download fas button-icon-right ms-2"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                type="button"
                class="btn btn-danger btn-header me-3 rounded-pill  text-capitalize"
                onClick={() =>
                  (window.location.href =
                    "https://bj88ph.live/af/Zd946zCP/inplay-official")
                }
              >
                Login/Register
              </button>
            </div>
          </div>
          {/* <!-- Collapsible wrapper --> */}
        </div>
        {/* <!-- Container wrapper --> */}
      </nav>
      {/* <!-- Navbar --> */}
      {/* <!-- Navbar --> */}
    </header>
  );
};

export default Header;
