import React from "react";

const Marquee = () => {
  return (
    <>
      <div className="marquee__container">
        <marquee behavior className="marquee__wrapper" scrollamount="12">
          <p className="maquee-text">
            Play with Filipinos’ most trusted online casino in the Philippines.
            Arcade. Table. Sports. Slots.&nbsp; Play with Filipinos’ most
            trusted online casino in the Philippines. Arcade. Table. Sports.
            Slots.&nbsp;
          </p>{" "}
        </marquee>
      </div>
    </>
  );
};

export default Marquee;
