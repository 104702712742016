import React from "react";

const Play = () => {
  return (
    <section className="play__container py-5">
      <div className="container">
        <div className="title d-flex justify-content-center mb-4">
          <h1 className="fw-bold play__title">Why play on InPlay?</h1>
        </div>
        <div className="row">
          <div className="col-lg-5 my-auto d-flex justify-content-end px-auto">
            <img
              src="https://inplay.ph/wp-content/uploads/2023/05/play-on-inplay.png"
              alt="play game"
              width="477px"
              height="480px"
              className="d-flex "
              srcset=""
            />
          </div>
          <div className="col-lg-7 ppx-0 mt-4">
            <div className="row gy-3 ">
              <div className="col right__col mx-4 w-75 d-flex justify-content-start">
                <div className="play__content__right ">
                  <h3
                    class="title-heading-left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "normal",
                      fontWeight: "500",
                      margin: "0",
                      fontStyle: "18px",
                      lineHeight: "1.2",
                      color: "#32ba7c",
                      marginBottom: "15px",
                    }}
                  >
                    Easy sign up. Play right away with InPlay
                  </h3>
                  <div
                    class="fusion-text fusion-text-4 fusion-text-no-margin"
                    // style='--awb-font-size:16px;--awb-line-height:1.3;--awb-text-color:var(--awb-color1);--awb-margin-bottom:0px;--awb-text-font-family:"Poppins";--awb-text-font-style:normal;--awb-text-font-weight:400;'
                  >
                    <p>
                      3 easy steps lang ang kailangan with InPlay. Sign up,
                      deposit, and play! Meron ka ng access sa biggest and most
                      trusted library of online casino games in the Philippines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col right__col mx-4 w-75 d-flex justify-content-end">
                <div className="play__content__right ">
                  <h3
                    class="title-heading-left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "normal",
                      fontWeight: "500",
                      margin: "0",
                      fontStyle: "18px",
                      lineHeight: "1.2",
                      color: "#32ba7c",
                      marginBottom: "15px",
                    }}
                  >
                    Easy sign up. Play right away with InPlay
                  </h3>
                  <div
                    class="fusion-text fusion-text-4 fusion-text-no-margin"
                    // style='--awb-font-size:16px;--awb-line-height:1.3;--awb-text-color:var(--awb-color1);--awb-margin-bottom:0px;--awb-text-font-family:"Poppins";--awb-text-font-style:normal;--awb-text-font-weight:400;'
                  >
                    <p>
                      3 easy steps lang ang kailangan with InPlay. Sign up,
                      deposit, and play! Meron ka ng access sa biggest and most
                      trusted library of online casino games in the Philippines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col right__col mx-4 w-75 d-flex justify-content-start">
                <div className="play__content__right ">
                  <h3
                    class="title-heading-left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "normal",
                      fontWeight: "500",
                      margin: "0",
                      fontStyle: "18px",
                      lineHeight: "1.2",
                      color: "#32ba7c",
                      marginBottom: "15px",
                    }}
                  >
                    Easy sign up. Play right away with InPlay
                  </h3>
                  <div
                    class="fusion-text fusion-text-4 fusion-text-no-margin"
                    // style='--awb-font-size:16px;--awb-line-height:1.3;--awb-text-color:var(--awb-color1);--awb-margin-bottom:0px;--awb-text-font-family:"Poppins";--awb-text-font-style:normal;--awb-text-font-weight:400;'
                  >
                    <p>
                      3 easy steps lang ang kailangan with InPlay. Sign up,
                      deposit, and play! Meron ka ng access sa biggest and most
                      trusted library of online casino games in the Philippines.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Play;
